import { CALL_API } from '../config';

export default (store: any, action: any) => {
  const callAPI = action[CALL_API];
  const {
    endpoint,
    timeout = null,
    params = {},
    normalizer = (resp: any) => resp,
    errorNormalizer = (resp: any) => resp,
    types,
    method,
  } = callAPI;
  if (typeof endpoint !== 'string') {
    throw new Error('Specify a string endpoint URL.');
  }
  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error('Expected an array of three action types.');
  }
  if (!types.every((type) => typeof type === 'string')) {
    throw new Error('Expected action types to be strings.');
  }
  let formData = {};
  formData = {
    ...params,
  };
  return {
    fullUrl: endpoint,
    formData,
    timeout,
    normalizer,
    errorNormalizer,
    types,
    method,
  };
};
