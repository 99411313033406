import { mapActionTypesToPrefix } from '@/common/utils/actionTypes';

export const actionTypesFactory = mapActionTypesToPrefix({
    SET_ACTIVE_ID: null
});

const initialState = '1';

export default (prefix: any) => (state = initialState, action: any) => {
    const actionTypes = actionTypesFactory(prefix);
    switch (action.type) {
        case actionTypes.SET_ACTIVE_ID:
            return action.payload.activeId;
        default:
            return state;
    }
};
