import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import {routerMiddleware} from 'connected-react-router';
import history from  '@/config/history'
import API from '@/common/middleware';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import reducer from './reducers';

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const initialState = typeof window !== 'undefined' ? window.__PRELOADED_STATE__ : {};

const middlewares = [
  thunk,
  API,
  routerMiddleware(history)
];
const persistConfig = {
  key: 'docV8',
  storage,
  whitelist: ['docV7']
}
const persistedReducer = persistReducer(persistConfig, reducer);
const store = createStore(
  persistedReducer,
  initialState,
  composeEnhancers(applyMiddleware(...middlewares))
  );
export default { store, persistor: persistStore(store) };;
