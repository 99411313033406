const NAMESPACE = "project";

const initialState = {
  servers: {},  //全部服务信息
  activeEnv: null, //当前环境
  globals: {}, //全局变量
  projectDetails: [], // 项目信息
};

// action名称
enum actionTypes {
  // updateServers = "updateServers",
  // updateGlobals = "updateGlobals",
  initProject = 'initProject'
}

export const projectReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case `${NAMESPACE}/${actionTypes.initProject}`:
      return {
        ...state,
        ...payload
      };

    default:
      return state;
  }
};

export default projectReducer;
