/**
 * @file reduce
 * @author wushaobo
 */
import { combineReducers } from "redux";
import apiDebug from "./apiDebug";
import doc from "./doc";
import docV7 from "./docV7";
import global from "./global";
import project from "./project";
import theme from "./theme";

export default combineReducers({
    doc,
    docV7,
    apiDebug,
    project,
    global,
    theme,
});
