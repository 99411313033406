import type { AxiosInstance } from "axios";
import axios from "axios";
import type { HYRequestConfig, HYRequestInterceptors } from "./type";

const DEAFULT_LOADING = true;

class HYRequest {
    instance: AxiosInstance;
    interceptors?: HYRequestInterceptors;
    showLoading: boolean;
    sourceMap: any;
    cancelToken: any;


    constructor(config: HYRequestConfig) {
        // 创建axios实例
        this.instance = axios.create(config);

        // 保存基本信息
        this.showLoading = config.showLoading ?? DEAFULT_LOADING;
        this.interceptors = config.interceptors;

        // 使用拦截器
        // 1.从config中取出的拦截器是对应的实例的拦截器
        this.instance.interceptors.request.use(
            this.interceptors?.requestInterceptor,
            this.interceptors?.requestInterceptorCatch,
        );
        this.instance.interceptors.response.use(
            this.interceptors?.responseInterceptor,
            this.interceptors?.responseInterceptorCatch,
        );

        this.sourceMap = new Map();

        // 2.添加所有的实例都有的拦截器
        this.instance.interceptors.request.use(
            (config: any) => {
                const url = config.url;
                // 同一个请求发送多次，如何保证获取的是最后一次的结果
                if (this.sourceMap.has(url)) {
                    const source = this.sourceMap.get(url);
                    source.cancel("Only need the last request");
                }
                const source = axios.CancelToken.source();
                this.sourceMap.set(url, source);
                this.cancelToken = source.cancel;
                config.cancelToken = source.token;
                return config;
            },
            (err: any) => {
                return err;
            },
        );

        this.instance.interceptors.response.use(
            (res: any) => {
              if (res?.code === 'ERR_CANCELED') {
                return res;
              } else {
                const data = res.data;
                return data;
              }
            },
            (err: any) => {
                // 例子: 判断不同的HttpErrorCode显示不同的错误信息
                // if (err.response.status === 404) {
                //   console.log('404的错误~');
                // }
                return err;
            },
        );
    }

    request<T = any>(config: HYRequestConfig<T>): Promise<T> {
        return new Promise((resolve, reject) => {
            // 1.单个请求对请求config的处理
            if (config.interceptors?.requestInterceptor) {
                config = config.interceptors.requestInterceptor(config);
            }

            // 2.判断是否需要显示loading
            if (config.showLoading === false) {
                this.showLoading = config.showLoading;
            }

            this.instance
                .request<any, T>(config)
                .then((res: any) => {
                  if (res?.code === 'ERR_CANCELED') {
                    reject(res);
                  }
                    // 1.单个请求对数据的处理
                    if (config.interceptors?.responseInterceptor) {
                        res = config.interceptors.responseInterceptor(res);
                    }
                    // 2.将showLoading设置true, 这样不会影响下一个请求
                    this.showLoading = DEAFULT_LOADING;

                    // 3.将结果resolve返回出去
                    resolve(res);
                })
                .catch((err) => {
                    // 将showLoading设置true, 这样不会影响下一个请求
                    this.showLoading = DEAFULT_LOADING;
                    reject(err);
                    return err;
                });
        });
    }

    get<T = any>(config: HYRequestConfig<T>): Promise<T> {
        return this.request<T>({ ...config, method: "GET" });
    }

    post<T = any>(config: HYRequestConfig<T>): Promise<T> {
        return this.request<T>({ ...config, method: "POST" });
    }

    delete<T = any>(config: HYRequestConfig<T>): Promise<T> {
        return this.request<T>({ ...config, method: "DELETE" });
    }

    patch<T = any>(config: HYRequestConfig<T>): Promise<T> {
        return this.request<T>({ ...config, method: "PATCH" });
    }
}

export default HYRequest;
