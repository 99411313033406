import { mapValues } from 'lodash';
import { combineReducers } from 'redux';
import { combineActionTypes } from '@/common/utils/actionTypes';
import themeMode, {actionTypesFactory as anchorActionTypesFactory} from './themeMode';

  const PREFIX = 'THEME';


  const actionTypesFactory = combineActionTypes(
     anchorActionTypesFactory
  );

  const reducersFactory = (prefix: any) => combineReducers(
     mapValues(
         {
          themeMode
         },
         reducerFactory => reducerFactory(prefix)
     )
 );

export const ACTIONS = actionTypesFactory(PREFIX);
export default reducersFactory(PREFIX);
