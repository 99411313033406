import { mapActionTypesToPrefix } from '@/common/utils/actionTypes';

export const baseInfoTypesFactory = mapActionTypesToPrefix({
    SET_DOC_BASE_INFO: null
});

const initialState: any = {};

export default (prefix: any) => (state = initialState, action: any) => {
    const actionTypes = baseInfoTypesFactory(prefix);
    switch (action.type) {
        case actionTypes.SET_DOC_BASE_INFO:
            return action.payload.info;
        default:
            return state;
    }
};
