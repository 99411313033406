import { getSaltCode } from "@/utils";
import { getCookie } from "@/utils/cache";
import HYRequest from "./request";
import { BASE_URL, TIME_OUT } from "./request/config";
import { LANGUAGE_MAP } from '@/pages/Doc/constant';

import { getLocale } from "umi";

const hyRequest = new HYRequest({
  baseURL: BASE_URL,
  timeout: TIME_OUT,
  interceptors: {
    requestInterceptor: (config: any) => {
      const salt = getSaltCode();
      const Authorization = getCookie(salt);
      if (config.headers && Authorization) {
        config.headers.Authorization = Authorization;
      }

      return config;
    },
    requestInterceptorCatch: (err: any) => {
      return err;
    },
    responseInterceptor: (res: any) => {
      return res;
    },
    responseInterceptorCatch: (err: any) => {
      return err;
    },
  },
});


export const hyRequestV8 = new HYRequest({
  baseURL: BASE_URL,
  timeout: TIME_OUT,

  interceptors: {
    requestInterceptor: (config: any) => {
      const salt = getSaltCode();
      const Authorization = getCookie(salt as string);

      config.headers["Accept-Language"] = getLocale() || "zh-CN";
      const language = getLocale();
      config.headers["Apipost-Language"] = (LANGUAGE_MAP as any)?.[language] || "zh-cn";


      if (config.headers && Authorization) {
        config.headers.Authorization = Authorization;
      }

      return config;
    },
    requestInterceptorCatch: (err: any) => {
      return err;
    },
    responseInterceptor: (res: any) => {
      return res;
    },
    responseInterceptorCatch: (err: any) => {
      return err;
    },
  },
});

export default hyRequest;
