 /**
 * @file doc-埋点
 * @author wushaobo
 */

import { mapValues } from 'lodash';
import { combineReducers } from 'redux';
import { combineActionTypes } from '@/common/utils/actionTypes';
import anchor, {actionTypesFactory as anchorActionTypesFactory} from './anchor';

 const PREFIX = 'DOC';


 const actionTypesFactory = combineActionTypes(
    anchorActionTypesFactory
 );

 const reducersFactory = (prefix) => combineReducers(
    mapValues(
        {
            anchor
        },
        reducerFactory => reducerFactory(prefix)
    )
);

export const ACTIONS = actionTypesFactory(PREFIX);
export default reducersFactory(PREFIX);
