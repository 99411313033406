import { mapActionTypesToPrefix } from '@/common/utils/actionTypes';

export const actionTypesFactory = mapActionTypesToPrefix({
    SET_THEME_COLOR: null
});

const initialState: any = 'white';

export default (prefix: any) => (state = initialState, action: any) => {
    const actionTypes = actionTypesFactory(prefix);
    switch (action.type) {
        case actionTypes.SET_THEME_COLOR:
            return action.payload.mode;
        default:
            return state;
    }
};
