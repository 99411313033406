/**
 * @file api request
 * @author wushaobo
 * @date 2022/03/04
 */
import http from '@/services/http';

export default (url: string, postData = {}, options = {}, method: any) => {
  const requestParams: any = {
    url,
    method: method || 'POST',
    params: postData,
  };
    // if (method === 'get') {
    //   const requestSearch = [];
    //   for (let k in postData) {
    //     requestSearch.push(`${k}=${postData[k]}`);
    //   }
    //   requestParams.url = `${url}${requestSearch.length ? `?${requestSearch.join('&')}` : ''}`;
    // } else {
    //   requestParams.data = postData;
    // }
  return http.request(requestParams).then((res: any) => {
    const responseData = res;
    return responseData;
    try {
        return responseData;
    } catch (error) {
        throw error
    }
    if (+responseData.code === requestStatus.NOAUTH) {
        window.open(url, '_self');
        return responseData;
    }
    if (+responseData.code === requestStatus.SUCCESS) {
        return responseData;
    }
    throw responseData;
  });
};
