 /**
 * @file doc-埋点
 * @author wushaobo
 */

 import { mapValues } from 'lodash';
 import { combineReducers } from 'redux';
 import { combineActionTypes } from '@/common/utils/actionTypes';
 import anchor, {actionTypesFactory as anchorActionTypesFactory} from './anchor';
 import baseInfo, {baseInfoTypesFactory as baseInfoActionTypesFactory} from './baseInfo';

  const PREFIX = 'DOC';


  const actionTypesFactory = combineActionTypes(
     anchorActionTypesFactory,
     baseInfoActionTypesFactory,
  );

  const reducersFactory = (prefix: any) => combineReducers(
     mapValues(
         {
             anchor,
             baseInfo,
         },
         reducerFactory => reducerFactory(prefix)
     )
 );

 export const ACTIONS = actionTypesFactory(PREFIX);
 export default reducersFactory(PREFIX);
