import { CALL_API } from '../config';
import getRequestOptions from './getRequestOptions';
import request from './request';

export default (store: any) => (next: Function) => (action: any) => {
  const callAPI = action[CALL_API];
  if (typeof callAPI === 'undefined') {
    return next(action);
  }
  const { fullUrl, formData, timeout, normalizer, errorNormalizer, types, method } = getRequestOptions(store, action);

  const [requestType, successType, failureType] = types;

  const actionWith = (data: any) => {
    const finalAction = Object.assign({}, action, data);
    delete finalAction[CALL_API];
    return finalAction;
  };
  next(actionWith({ type: requestType }));
  return request(fullUrl, formData, { timeout }, method).then(
    (response: any) => {
      const payload = normalizer(response);
      next(actionWith({ payload, type: successType }));
      return payload;
    },
    (error = {}) => {
      const payload = errorNormalizer(error, error);
      next(actionWith({ type: failureType, error, payload }));
      throw error;
    },
  );
};
