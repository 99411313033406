import keyMirror from 'keymirror';
import { isArray, mapValues } from 'lodash';

export const mapActionTypesToPrefix = (actionTypes: any) => (prefix: any) =>
  mapValues(keyMirror(actionTypes), (actionType: string) => {
    if (isArray(prefix)) {
      return `${prefix.join('_').toUpperCase()}_${actionType}`;
    }
    if (prefix) {
      return `${prefix.toUpperCase()}_${actionType}`;
    }
    return `${actionType}`;
  });

export const combineActionTypes =
  (...allActionTypes: any[]) =>
  (prefix: any) => {
    return allActionTypes
      .map((actionTypes) => actionTypes(prefix))
      .reduce(
        (memo, item) => ({
          ...memo,
          ...item,
        }),
        {},
      );
  };
