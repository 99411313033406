import { DEFAULT_RUNTIME_HEADERS } from "./constant/global";

const NAMESPACE = "global";

const initialState = {
    runtimeHeaders: DEFAULT_RUNTIME_HEADERS,
    isMobile: false,
    showTopNavMore: false
};

// action名称
enum actionTypes {
    updateRuntimeHeader = "updateRuntimeHeader",
    updateIsMobile = "updateIsMobile",
    updateShowTopNavMore = "updateShowTopNavMore"
}

export const projectReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case `${NAMESPACE}/${actionTypes.updateRuntimeHeader}`:
            return {
                ...state,
                runtimeHeaders: payload,
            };
        case `${NAMESPACE}/${actionTypes.updateIsMobile}`:
            return {
                ...state,
                isMobile: payload
            }
        case `${NAMESPACE}/${actionTypes.updateShowTopNavMore}`:
            return {
                ...state,
                showTopNavMore: payload
            }
        default:
            return state;
    }
};

export default projectReducer;
