import { mapValues } from 'lodash';
import { combineReducers } from 'redux';
import { combineActionTypes } from '@/common/utils/actionTypes';
import activeId, { actionTypesFactory as activeIdActionTypesFactory } from './activeId';

const PREFIX = 'API';


 const actionTypesFactory = combineActionTypes(
    activeIdActionTypesFactory
 );

 const reducersFactory = (prefix) => combineReducers(
    mapValues(
        {
            activeId
        },
        reducerFactory => reducerFactory(prefix)
    )
);

export const ACTIONS = actionTypesFactory(PREFIX);
export default reducersFactory(PREFIX);
