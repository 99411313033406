import { mapActionTypesToPrefix } from '@/common/utils/actionTypes';

export const actionTypesFactory = mapActionTypesToPrefix({
    SET_DOC_NEW_API: null
});

const initialState: any[] = [];

export default (prefix: any) => (state = initialState, action: any) => {
    const actionTypes = actionTypesFactory(prefix);
    switch (action.type) {
        case actionTypes.SET_DOC_NEW_API:
            return action.payload.items;
        default:
            return state;
    }
};
