export default {
  /**
   * 成功
   */
  SUCCESS: 200,
  /**
   * 无权限
   */
  NOAUTH: 403,
};

export const CALL_API = 'Call API';
