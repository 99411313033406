
export type TYPE_RUNTIME_HEADER = {
  key: string;
  value: string;
  is_checked: 1 | -1;
};

export const DEFAULT_RUNTIME_HEADERS: TYPE_RUNTIME_HEADER[] = [
  {
    key: 'Accept',
    value: '*/*',
    is_checked: 1,
  },
  {
    key: 'Accept-Encoding',
    value: 'gzip, deflate, br',
    is_checked: 1,
  },
  {
    key: 'User-Agent',
    value: 'PostmanRuntime-ApipostRuntime/1.1.0',
    is_checked: 1,
  },
  {
    key: 'Connection',
    value: 'keep-alive',
    is_checked: 1,
  },
]; // apipost 默认请求头
