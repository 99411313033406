import { ConfigProvider, message } from 'antd';
import { Provider } from 'react-redux';
import zhCN from 'antd/locale/zh_CN';
import store from '@/modules/store';


// 自定义layout：header、slideMenu、footer等；
export const rootContainer = (container: any) => {
  /* eslint-disable */
  return (
    <Provider store={store.store}>

      <div id="pageMain" style={{ height: '100%' }}>
        <ConfigProvider locale={zhCN}>
          {/* 可以在config中关闭layout，然后在这里加入自定义的layout； */}
          {container}
        </ConfigProvider>
      </div>
    </Provider>

  );
};

export function onRouteChange(props: any) {
  const { location, routes } = props;
  // 路由变更时，动态给body添加class，实现避免修改全局组件样式时，出现样式覆盖；
  // console.log(location, routes, '3324234');
  //    `<script>
  //   var _hmt = _hmt || [];
  //   (function () {
  //     var hm = document.createElement("script");
  //     hm.src = "https://hm.baidu.com/hm.js?a046ce178828e393614822a297b8d296";
  //     var s = document.getElementsByTagName("script")[0];
  //     s.parentNode.insertBefore(hm, s);
  //   })();
  // </script>
  // <script>
  //   (function () {
  //     var src = (document.location.protocol == "http:") ?
  //       "http://js.passport.qihucdn.com/11.0.1.js?07ef9520f5511356899444a093d03ded" :
  //       "https://jspassport.ssl.qhimg.com/11.0.1.js?07ef9520f5511356899444a093d03ded";
  //     document.write('<script async src="' + src + '" id="sozz"><\/script>');
  //   })();
  // </script>`;
  // 插入百度统计脚本
  const baiduStatCode = "a046ce178828e393614822a297b8d296";
  const hmScript = document.createElement("script");
  hmScript.src = `https://hm.baidu.com/hm.js?${baiduStatCode}`;
  hmScript.async = true;
  document.body.appendChild(hmScript);

  // 插入奇虎 360 统计脚本
  const sozzScript = document.createElement("script");
  const src = (document.location.protocol === "http:")
    ? "http://js.passport.qihucdn.com/11.0.1.js?07ef9520f5511356899444a093d03ded"
    : "https://jspassport.ssl.qhimg.com/11.0.1.js?07ef9520f5511356899444a093d03ded";
  sozzScript.src = src;
  sozzScript.id = "sozz";
  sozzScript.async = true;
  document.body.appendChild(sozzScript);
}
